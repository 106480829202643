import React from "react";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Utils from '../../data/Utils'
import PlanetCanvas from '../canvas/PlanetCanvas'
import PlanetSvg from '../canvas/PlanetSvg'

import Dialog from '@mui/material/Dialog';
import Draggable from "react-draggable";
import Paper from '@mui/material/Paper';
import html2canvas from 'html2canvas'

import jpeg from "../../media/jpeg.png"
import svg from "../../media/svg.png"

import { sprintf } from "sprintf-js";
import { withTranslation } from 'react-i18next';

const style = {};
const InverseMap = [0, 6, 3, 1, 4, 2, 5];
const Name = ["Sun", "Mercury", "Venus", "Mars", "Jupiter", "Saturn ", "Moon"];

const PaperComponent = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

var data = []
var remapTl = []

class PlanetDialog extends React.Component {
    constructor(props) {
        super(props)      
        
        this.state = {
            open: this.props.open,
            planetJpgDisplay: "none",
            planetSvgDisplay: "inherit"
        }

        this.handleClose = this.handleClose.bind(this)
        this.handleTitleClick = this.handleTitleClick.bind(this)
        this.saveAsJPEG = this.saveAsJPEG.bind(this)
        this.saveAsSVG = this.saveAsSVG.bind(this)
    }

    componentDidMount() {
      
    }

    handleClose() {
      this.props.planetCloseHandleCallback()
    }

    handleTitleClick() {
      this.props.planetBringToFrontHandleCallback()
    }

    init() {
      for (var i=0; i<7; i++) {
        var tl = Utils.trueLong(InverseMap[i], this.props.canon.value, this.props.kYDay, this.props.hasBija);
        remapTl[i] = parseInt(tl/30);

        var arr = [Name[InverseMap[i]], sprintf("(%1$1d)",i+1), Utils.sdm(tl)]

        data[i] = arr
      }    
    }

    saveAsSVG() {
      this.doSaveAsSVG()
    }

    doSaveAsSVG() {      
      var d = document.getElementById("imagePlanetSvg")

      var filename = (this.props.yearSave) ? sprintf("hic-planet_%1$s.svg", this.props.yearSave) : sprintf("hic-planet.jpg")

      var aDownload = document.getElementById("aDownload")

      var svgData = d.outerHTML.replace('id="imagePlanetSvg"', 'xmlns="http://www.w3.org/2000/svg" ');

      var svgBlob = new Blob([svgData], {type:"image/svg+xml;charset=utf-8"})
      var svgUrl = URL.createObjectURL(svgBlob);

      aDownload.download = filename
      aDownload.href = svgUrl
      aDownload.click()   
    }
    
    saveAsJPEG() {
      this.setState(
        {
          planetSvgDisplay: "none",
          planetJpgDisplay: "inherit"
        }, () => {
          setTimeout(this.doSaveAsJpeg(), 500)
        })
    }

    doSaveAsJpeg() {
      var d = document.getElementById("imagePlanetJpg")
      html2canvas(d).then(canvas => {
        var imageData = canvas.toDataURL("image/png");
        var newData = imageData.replace(/^data:image\/png/, "data:application/octet-stream");
        
        var filename = (this.props.yearSave) ? sprintf("hic-planet_%1$s.jpg", this.props.yearSave) : sprintf("hic-planet.jpg")

        var aDownload = document.getElementById("aDownload")
        aDownload.download = filename
        aDownload.href = newData
        aDownload.click()      

        this.setState( {
          planetJpgDisplay: "none",
          planetSvgDisplay: "inherit"
        })
      }
      )
    }

    render() {
      const { t } = this.props;
      
      this.init()

      return (
        <div  onMouseDown={this.handleTitleClick}>          
          <Dialog
            PaperComponent={PaperComponent}
            disableEnforceFocus
            BackdropProps={{ style: { backgroundColor: "transparent" } }}
            style={{ pointerEvents: 'none', zIndex: this.props.zIndex }}
            PaperProps={{ style: { pointerEvents: 'auto' }, sx: {width: 153, position: "fixed", left: 340, top: 300} }}
            open={this.props.open}
            onClose={this.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <a id="aDownload" style={{display: "none"}}></a>
              <div id="draggable-dialog-title" style={{cursor: "move"}}>                  
                  <div className="Hic-Dialog-Planet-Title" style={{marginLeft: 8, marginTop: 6}}>Planets</div>
                  <div style={{textAlign: "right", marginRight: 2}}>
                    <IconButton aria-label="dclose" onPointerDown={this.saveAsSVG}>
                        <img src={svg} width={16} height={16} />
                      </IconButton>
                      <IconButton aria-label="dclose" onPointerDown={this.saveAsJPEG}>
                        <img src={jpeg} width={16} height={16} />
                      </IconButton>
                      <IconButton style={{width: 16, height: 16}} onPointerDown={this.handleClose}>
                        <CloseIcon />
                      </IconButton>
                  </div>
              </div>
              <div id="imagePlanetJpg" style={{display: this.state.planetJpgDisplay, marginTop: 8}}>  
                  <PlanetCanvas 
                    angle={remapTl}/>                
                  {data.map(
                      
                      (m, index) => 
                      <div key={index} style={{marginLeft: 8, marginRight: 8}}>
                          {
                            m.map(
                              (m1, index1) => <div key={index1} className={index1 == 0 ? "Hic-Planet-Column1" : (index1 == 1 ? "Hic-Planet-Column2" : "Hic-Planet-Column3")}>{m1}</div>
                            )
                          }
                        </div>
                  )}
                  <div style={{height: 16}}></div>
              </div>
              <svg id="imagePlanetSvg" style={{display: this.state.planetSvgDisplay, height: 290, width: "100%", marginTop: 8}}>
                <PlanetSvg 
                      angle={remapTl}
                      month={data} />
              </svg>                                
            </Box>
          </Dialog>        
        </div>
      );
    }
}

export default withTranslation()(PlanetDialog);