import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import logo from "../../media/logo.png"

import { withTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  height: "90%",
  overflow:'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

class TithiSvg extends React.Component {
    constructor(props) {
        super(props)      
        
        this.state = {
            open: this.props.open,          
        }

    }

    componentDidMount() {
      
    }

    

    render() {
      const { t } = this.props;

      var f0 = parseInt(this.props.data.frac0);
      var f1 = parseInt(this.props.data.frac1);
      var f2 = parseInt(this.props.data.frac2);
      var f3 = parseInt(this.props.data.frac3);
      var f4 = parseInt(this.props.data.frac4);
      var t0 = this.props.data.tit0;
      var t1 = this.props.data.tit1;
      var t2 = this.props.data.tit2;
      var t3 = this.props.data.tit3;
      var t4 = this.props.data.tit4;
      var msg = this.props.data.message;
      var msg1 = this.props.data.message1;

      var offsetX = 120;
      var y = this.props.y
      var fontSize = 10

      return (
        <svg>          
          <rect x={offsetX+42} y={y} width={40} height={32} fill="#D2D2D2" />
          <rect x={offsetX+122} y={y} width={40} height={32} fill="#D2D2D2" />
          <rect x={offsetX-38} y={y} width={40} height={32} fill="#D2D2D2" />

          <rect x={offsetX+42+40} y={y} width={40} height={32} fill="#FFFFFF" />
          <rect x={offsetX-118+40} y={y} width={40} height={32} fill="#FFFFFF" />
          <rect x={offsetX-38+40} y={y} width={40} height={32} fill="#FFFFFF" />

          <line x1={offsetX+f1} y1={y+15} x2={offsetX + f2} y2={y+15}  stroke='#000000' />
          <line x1={offsetX+f1} y1={y+16} x2={offsetX + f2} y2={y+16}  stroke='#FF0000' />

          <line x1={offsetX+f2} y1={y+15} x2={offsetX + f3} y2={y+15}  stroke='#000000' />
          <line x1={offsetX+f1} y1={y+15} x2={offsetX + f1} y2={y+18}  stroke='#000000' />
          <line x1={offsetX+f2} y1={y+15} x2={offsetX + f2} y2={y+18}  stroke='#000000' />
          <line x1={offsetX+f3} y1={y+15} x2={offsetX + f3} y2={y+18}  stroke='#000000' />

          <line x1={offsetX+f0} y1={y+15} x2={offsetX + f0} y2={y+13}  stroke='#000000'  display={(f0 != -500) ? "inline" : "none"}/>
          <line x1={offsetX+f4} y1={y+15} x2={offsetX + f4} y2={y+13}  stroke='#000000'  display={(f0 != -500) ? "inline" : "none"} />

          <text x={110+f1} y={y+12} style={{fontFamily: "Noto Serif", fontSize: fontSize}}>{t1}</text>
          <text x={110+f2} y={y+12} style={{fontFamily: "Noto Serif", fontSize: fontSize}}>{t2}</text>

          <text x={110+f3} y={y+12} style={{fontFamily: "Noto Serif", fontSize: fontSize}}>{t3}</text>
          <text x={110+f0} y={y+12} style={{fontFamily: "Noto Serif", fontSize: fontSize}}>{t0}</text>
          <text x={110+f4} y={y+12} style={{fontFamily: "Noto Serif", fontSize: fontSize}}>{t4}</text>
          <text x={119+f1} y={y+25} style={{fontFamily: "Noto Serif", fontSize: fontSize}}>{msg}</text>
          <text x={121+f2} y={y+25} style={{fontFamily: "Noto Serif", fontSize: fontSize}}>{msg1}</text>

        </svg>
      );
    }
}

export default withTranslation()(TithiSvg);