import Hic from './components/Hic';
import './App.css';

function App() {
  return (
      <div className="App">
        <Hic />
      </div>
  );
}

export default App;
