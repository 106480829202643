import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import logo from "../../media/logo.png"

import { withTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  height: "90%",
  overflow:'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

class BaseLineSvg extends React.Component {
    constructor(props) {
        super(props)      
        
        this.state = {
            open: this.props.open,          
        }

    }

    componentDidMount() {
      
    }

    

    render() {
      const { t } = this.props;

      var y = this.props.y
      var offsetX = 120;

      return (
        <svg>          
          <rect x={offsetX+42} y={y} width={40} height={32} fill="#D2D2D2" />
          <rect x={offsetX+122} y={y} width={40} height={32} fill="#D2D2D2" />
          <rect x={offsetX-38} y={y} width={40} height={32} fill="#D2D2D2" />

          <rect x={offsetX+42+40} y={y} width={40} height={32} fill="#FFFFFF" />
          <rect x={offsetX-118+40} y={y} width={40} height={32} fill="#FFFFFF" />
          <rect x={offsetX-38+40} y={y} width={40} height={32} fill="#FFFFFF" />

          <line x1={offsetX-80} y1={y+15} x2={offsetX + 160} y2={y+15}  stroke='#000000' />

          <line x1={offsetX} y1={y+13} x2={offsetX + 80} y2={y+13}  stroke='#FF0000' />
          <line x1={offsetX} y1={y+14} x2={offsetX + 80} y2={y+14}  stroke='#FF0000' />
          <line x1={offsetX} y1={y+12} x2={offsetX + 80} y2={y+12}  stroke='#FF0000' />

          <line x1={offsetX-20} y1={y+16} x2={offsetX + 60} y2={y+16}  stroke='#000000' />
          <line x1={offsetX-20} y1={y+17} x2={offsetX + 60} y2={y+17}  stroke='#000000' />
          <line x1={offsetX-20} y1={y+18} x2={offsetX + 60} y2={y+18}  stroke='#000000' />
          <line x1={offsetX} y1={y+5} x2={offsetX} y2={y+15}  stroke='#000000' />
          <line x1={offsetX+80} y1={y+5} x2={offsetX+80} y2={y+15}  stroke='#000000' />

        </svg>
      );
    }
}

export default withTranslation()(BaseLineSvg);