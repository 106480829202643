import React from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import logo from "../../media/logo.png"

import { withTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  height: "90%",
  overflow:'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

class PlanetSvg extends React.Component {
    constructor(props) {
        super(props)      
    }

    componentDidMount() {
      
    }

    

    render() {
      const { t } = this.props;

      var pi6 = Math.PI/6;
      var pi25 = Math.PI/25;
      var pi30 = Math.PI/30;
  
      var x;
      var y;
      var r = 75 
      var off=2;
      var fontSize = 8

      var x1 = parseInt((-55 * Math.cos(this.props.angle[0] * pi6 - pi25) + r+off + 3));		//55=73
      var y1 = parseInt((-55 * Math.sin(this.props.angle[0] * pi6 - pi25) + r+off - 3));

      var x2 = parseInt((-49 * Math.cos(this.props.angle[1] * pi6 - pi25) + r+off + 3));	//49=65	
      var y2 = parseInt((-49 * Math.sin(this.props.angle[1] * pi6 - pi25) + r+off - 3));

      var x3 = parseInt((-59 * Math.cos(this.props.angle[2] * pi6) + r+off + 3));//59=79		
      var y3 = parseInt((-59 * Math.sin(this.props.angle[2] * pi6) + r+off - 3));

      var x4 = parseInt((-51 * Math.cos(this.props.angle[3] * pi6) + r+off + 3));	//51=68	
      var y4 = parseInt((-51 * Math.sin(this.props.angle[3] * pi6) + r+off - 3));

      var x5 = parseInt((-66 * Math.cos(this.props.angle[4] * pi6 - pi25) + r+off + 5));//66=88		
      var y5 = parseInt((-66 * Math.sin(this.props.angle[4] * pi6 - pi25) + r+off - 3));

      var x6 = parseInt((-50 * Math.cos(this.props.angle[5] * pi6 + pi25) + r+off + 3));	//50=67	
      var y6 = parseInt((-50 * Math.sin(this.props.angle[5] * pi6 + pi25) + r+off - 3));

      var x7 = parseInt((-66 * Math.cos(this.props.angle[6] * pi6) + r+off + 3));		
      var y7 = parseInt((-66 * Math.sin(this.props.angle[6] * pi6) + r+off - 3));

      var startX = off
      var startY = off
      var endX = 2*r+off
      var endY = 2*r+off

      var rx = (endX - startX) * 0.5,  // get radius for x 
      ry = (endY - startY) * 0.5,  // get radius for y
      cx = startX + rx,            // get center position for ellipse
      cy = startY + ry,
      a = 0,                       // current angle
      step = 0.01,                 // angle step
      pi2 = Math.PI * 2;

      var oval = "M"+(cx + rx)+ " " + cy
      for(; a < pi2; a += step) {
        oval += " L" + (cx + rx * Math.cos(a)) + " " + (cy + ry * Math.sin(a))
      }
      oval += "Z"
      var h = 170
      var width=153

      return (
        <svg>          
            
          <rect x={0} y={0} width={width} height={153} fill="#FFFF64" />

          <path fill="#FFFF64" stroke="#000000" d={oval} />

          <line x1={62+off} y1={1+off} x2={62+off} y2={149+off}  stroke='#000000' />
          <line x1={88+off} y1={1+off} x2={88+off} y2={149+off}  stroke='#000000' />
          <line x1={1+off} y1={62+off} x2={149+off} y2={62+off}  stroke='#000000' />
          <line x1={1+off} y1={88+off} x2={149+off} y2={88+off}  stroke='#000000' />
          <line x1={62+off} y1={88+off} x2={22+off} y2={128}  stroke='#000000' />
          <line x1={62+off} y1={62+off} x2={22+off} y2={22+off}  stroke='#000000' />
          <line x1={88+off} y1={62+off} x2={127+off} y2={22+off}  stroke='#000000' />
          <line x1={88+off} y1={88+off} x2={127+off} y2={127+off}  stroke='#000000' />


          <text x={y1} y={x1} style={{fontFamily: "Noto Serif", fontSize: fontSize}}>1</text>
          <text x={y2} y={x2} style={{fontFamily: "Noto Serif", fontSize: fontSize}}>2</text>
          <text x={y3} y={x3} style={{fontFamily: "Noto Serif", fontSize: fontSize}}>3</text>
          <text x={y4} y={x4} style={{fontFamily: "Noto Serif", fontSize: fontSize}}>4</text>
          <text x={y5} y={x5} style={{fontFamily: "Noto Serif", fontSize: fontSize}}>5</text>
          <text x={y6} y={x6} style={{fontFamily: "Noto Serif", fontSize: fontSize}}>6</text>
          <text x={y7} y={x7} style={{fontFamily: "Noto Serif", fontSize: fontSize}}>7</text>

          {this.props.month.map(                        
                        (m, index) => <svg>
                          <text x={8} y={h+index*18} style={{fontFamily: "Noto Serif", fontSize: 10}}>{m[0]}</text>
                          <text x={width/2} y={h+index*18} style={{fontFamily: "Noto Serif", fontSize: 10}}>{m[1]}</text>
                          <text x={width-8} y={h+index*18} style={{fontFamily: "Noto Serif", fontSize: 10, dominantBaseline: 'end', textAnchor: 'end'}}>{m[2]}</text>
                          </svg>
          )}

        </svg>
      );
    }
}

export default withTranslation()(PlanetSvg);